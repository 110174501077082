import { faFileMedical, faFilePdf, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { Loader } from '../../../../components/Loader/Loader'
import { ModalAgregarMuestras } from './ModalAgregarMuestras'
import { ModalCopiarDatos } from './ModalCopiarDatos'
import { ModalEliminarMuestra } from './ModalEliminarMuestra'
import { ModalEditarMuestra } from './ModalEditarMuestra'
import { isNoVerificada } from '../../../../helpers/helpers'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { useFetch } from '../../../../hooks/useFetch'

export const DadesMostres = ({ setMuestras, solicitud_id, setSolicitud_id, fetchSolicitudesMuestras,
    tipoTest, muestras, fetchSolicitudesMuestrasData, setIndexFocusWizard, fetchParametrosData, indexFocusWizard }) => {

    // Modal de editar muestra

    const [showModalEditarMuestra, setShowModalEditarMuestra] = useState({
        value: false,
        id: -1
    });

    const handleCloseModalEditarMuestra = () => setShowModalEditarMuestra({
        ...showModalEditarMuestra,
        value: false,
    });

    const handleShowModalEditarMuestra = (id) => {
        setShowModalEditarMuestra({
            value: true,
            id
        })
    };

    // Modal de copiar datos de una muestra

    const [showModalCopiarDatos, setShowModalCopiarDatos] = useState({
        value: false,
        form: null
    });

    const handleCloseModalCopiarDatos = () => {
        setMuestras([])

        if (solicitud_id) {
            // console.log('fetchSolicitudesMuestras en modal copiar')
            fetchSolicitudesMuestras({
                id: solicitud_id
            })
        }
        setShowModalCopiarDatos({
            value: false,
            form: null
        });
    }
    const handleShowModalCopiarDatos = (form) => {
        setShowModalCopiarDatos({
            value: true,
            form
        });
    }

    // Modal eliminar muestra 

    const [showModalEliminarMuestra, setShowModalEliminarMuestra] = useState({
        value: false,
        id: -1,
        ticket_link: null
    });

    const handleCloseModalEliminarMuestra = () => setShowModalEliminarMuestra({
        ...showModalEliminarMuestra,
        value: false,
        ticket_link: null
    });

    const handleShowModalEliminarMuestra = (id, ticket_link) => {
        setShowModalEliminarMuestra({
            value: true,
            id,
            ticket_link
        })
    }

    // Modal Agregar muestras

    const [showModalAgregarMuestras, setShowModalAgregarMuestras] = useState(false);

    const handleCloseModalAgregarMuestras = () => setShowModalAgregarMuestras(false);
    const handleShowModalAgregarMuestras = () => setShowModalAgregarMuestras(true);

    // const [showStatusAgregarMuestras, setShowStatusAgregarMuestras] = useState(false);

    const isCompleteMuestras = () => {

        let muestrasTemp = [...muestras]

        if (muestrasTemp.length === 0)
            return false

        for (let i = 0; i < muestrasTemp.length; i++) {
            if (isNoVerificada(muestrasTemp[i])) {

                return false
            }
        }

        return true
    }

    // Cambiar a pendiente_pago

    const [fetchEditarEstadoSolicitud, fetchEditarEstadoSolicitudData] = useFetch('PATCH', `/clientes/solicitudes`)

    const handleClickCambiarAPendientePago = _ => {

        fetchEditarEstadoSolicitud({
            id: `${solicitud_id}/estado`,
            body: {
                estado_id: 'pendiente_pago'
            }
        })
    }

    useEffect(() => {

        if (fetchEditarEstadoSolicitudData.data?.status === 'Success') {
            setIndexFocusWizard(indexFocusWizard + 1);
        }

    }, [fetchEditarEstadoSolicitudData])


    return (
        <>
            <div className="container animate__faster animate__animated animate__fadeInLeft">
                <div className="py-3 px-0">
                    <div className="">
                        <h5 className='mb-4 text-center'>Dades mostres</h5>
                    </div>

                    <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-end">
                            <h5 className='mb-0 text-center h6'>Mostres</h5>
                            <Button variant='outline-primary' onClick={handleShowModalAgregarMuestras}>
                                <FontAwesomeIcon icon={faFileMedical} className='me-2' /> Afegir mostres
                            </Button>
                        </div>
                    </div>

                    <Table bordered responsive hover className='tableMain'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Adreça</th>
                                <th>Pis</th>
                                <th>Porta</th>
                                <th>Localització de la bigueta</th>
                                <th>Estat</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                muestras.sort((a, b) => a.id - b.id).map(item => {

                                    return (
                                        <tr key={item.id} className={isNoVerificada(item) ? 'muestra_pendiente' : ''}>
                                            <td valign='middle'>{item.id}</td>
                                            <td valign='middle'>{item.direccion}</td>
                                            <td valign='middle'>{item.piso}</td>
                                            <td valign='middle'>{item.puerta}</td>
                                            <td valign='middle'>{fetchParametrosData?.data?.data?.localizaciones_vigueta?.find(item2 => item2.id === item.localizacion_vigueta)?.nombre}</td>
                                            <td valign='middle'>{item.estado}</td>
                                            <td valign='middle' className='shrink-td'>
                                                {
                                                    item.estado !== 'Anàlisi finalitzat' && item.estado !== 'Anul·lada' && <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.solicitud_id}`}>Editar mostra</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button variant="dark" size='sm' className='' onClick={() => handleShowModalEditarMuestra(item.id)}>
                                                                <FontAwesomeIcon icon={faPencilAlt} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                }

                                                {
                                                    item.estado !== 'Anàlisi finalitzat' && item.estado !== 'Anul·lada' &&
                                                    <Button variant="secondary" size='sm' className='ms-3' onClick={() => handleShowModalEliminarMuestra(item.id, item.ticket_link)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                }

                                                {
                                                    item.estado === 'Anàlisi finalitzat' && <a
                                                        className="btn btn-primary btn-sm ms-3 my-1"
                                                        id="download-file"
                                                        href={item.doc_link}
                                                        target='_blank'
                                                        rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                    </a>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                ((!solicitud_id && muestras.length === 0) || (solicitud_id && fetchSolicitudesMuestrasData?.data?.data?.muestras?.length === 0)) && <>
                                    <tr className='text-center'>
                                        <td className='py-5 ' colSpan={7}>
                                            <h5 className='fw-normal'>No hi ha sol·licituds per mostrar</h5>
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                fetchSolicitudesMuestrasData?.loading &&
                                <tr>
                                    <td colSpan={7} className="text-center ResultadoTest__TdLoader">
                                        <Loader />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>

                </div>
            </div>
            <Modal.Footer className='container border-0 flex-nowrap justify-content-between pb-0'>
                <div className='m-0'>
                    <small className='mb-0'>
                        En el cas que hagis copiat les dades, revisa't la informació de cada mostra
                    </small>
                </div>
                <div className='m-0'>
                    {/* <Button variant="default" onClick={() => setIndexFocusWizard(indexFocusWizard - 1)} className='ms-3 px-3'>
                        enrere
                    </Button> */}

                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3 px-3"
                        handleSubmit={handleClickCambiarAPendientePago} fetchDataLoading={[fetchEditarEstadoSolicitudData.loading]}
                        value='Continuar' disabled={!isCompleteMuestras()}
                    />
                </div>
            </Modal.Footer>

            {/* <ModalStatus setShowStatus={setShowStatusAgregarMuestras} showStatus={showStatusAgregarMuestras}>
                <div className="py-3 text-center">
                    <h5 className='mb-0 fw-normal'>S'han afegit correctament les mostres</h5>
                </div>
            </ModalStatus> */}

            <ModalAgregarMuestras
                showModalAgregarMuestras={showModalAgregarMuestras}
                handleCloseModalAgregarMuestras={handleCloseModalAgregarMuestras}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                solicitud_id={solicitud_id}
                tipoTest={tipoTest}
                setMuestras={setMuestras}
                setSolicitud_id={setSolicitud_id}
                muestras={muestras}
            />

            <ModalCopiarDatos
                showModalCopiarDatos={showModalCopiarDatos}
                handleCloseModalCopiarDatos={handleCloseModalCopiarDatos}
                handleCloseModalEditarMuestra={handleCloseModalEditarMuestra}
                solicitud_id={solicitud_id}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                setMuestras={setMuestras}
            />

            <ModalEliminarMuestra
                showModalEliminarMuestra={showModalEliminarMuestra}
                handleCloseModalEliminarMuestra={handleCloseModalEliminarMuestra}
                solicitud_id={solicitud_id}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                setMuestras={setMuestras}
            />

            <ModalEditarMuestra
                showModalEditarMuestra={showModalEditarMuestra}
                handleCloseModalEditarMuestra={handleCloseModalEditarMuestra}
                handleShowModalCopiarDatos={handleShowModalCopiarDatos}
                fetchParametrosData={fetchParametrosData}
                muestras={muestras}
                solicitud_id={solicitud_id}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                setMuestras={setMuestras}
            />
        </>
    )
}
