import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { CardLogin } from '../../components/CardLogin/CardLogin';
import { useFetch } from '../../hooks/useFetch';
import jwt from 'jwt-decode' // import dependency

import './Login.css'
import { images } from '../../helpers/images';
import { Footer } from '../../components/Footer/Footer';
import { FooterLogin } from '../../components/Footer/FooterLogin';

export const Login = ({ history }) => {

    const { dispatch } = useContext(AuthContext);

    const [fetchLoginColegiado, fetchLoginColegiadoData] = useFetch('POST', `/clientes/auth/loginColegiado`)

    const [fetchLoginExterno, fetchLoginExternoData] = useFetch('POST', `/clientes/auth/loginExterno`)

    // const handleLogin = (values) => {

    //     fetchUser({
    //         body: values
    //     })
    // }


    useEffect(() => {

        // console.log('fetchUserData', fetchLoginColegiadoData, fetchLoginExternoData)

        if (fetchLoginColegiadoData.data || fetchLoginExternoData.data) {

            const token = fetchLoginColegiadoData?.data?.data?.token || fetchLoginExternoData?.data?.data?.token

            if (token) {

                const lastPath = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_lastPath') || '/';
                const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE)).lang || process.env.REACT_APP_LANGUAGE_DEFAULT;

                const user = jwt(token).user
                dispatch({
                    type: types.login,
                    payload: {
                        ...user,
                        token: token,
                    },
                    lang
                });

                history.replace(lastPath);

            }

            // if(fetchLoginColegiadoData.data?.message?.indexOf('codi') || fetchLoginExternoData.data?.message?.indexOf('codi')){
            //     setShowModalVerificar(true)
            // }

        }

    }, [fetchLoginColegiadoData, fetchLoginExternoData])

    return (
        <div className="App">
            <header className="App-header min-vh-100">
                <section id="login-start" className="float-start flex-grow-1">
                    <img src={images('./logo.png')} className="card-img" alt="logo" id="logo-login" />
                    <CardLogin
                        fetchLoginColegiado={fetchLoginColegiado}
                        fetchLoginColegiadoData={fetchLoginColegiadoData}
                        fetchLoginExterno={fetchLoginExterno}
                        fetchLoginExternoData={fetchLoginExternoData}
                    />
                    <FooterLogin />

                </section>
                <section id='login-end' className='d-flex flex-column justify-content-between shadow'>
                    <div className="card-test-info">
                        <h4>Test aluminós</h4>
                        <p>Has accedit a l'espai des d'on podràs registrar i tramitar les mostres per fer les proves del test aluminós.</p>
                        <p className='mb-0'>Llegeix atentament quin és el procediment abans d'iniciar el tràmit <a href="https://www.cateb.cat/labtests/test-ciment-aluminos/" target='_blank'>Test aluminós</a>.</p>

                    </div>
                    <div className="card-test-info-2 mb-0 pb-2 pb-lg-5">
                        <p>
                            L'horari per a la recepció de les mostres és de dilluns a dijous de 9 a 17h i divendres fins les 14:30h.
                        </p>
                        {/* {
                            ((new Date().getTime()) < (new Date('9/01/2023').getTime()) ) &&
                            <p>
                                L'horari a l'agost és de 9 a 14:30 h.
                            </p>
                        } */}
                        <p>
                            Si tens dubtes o vols altres proves contacta'ns al 932 40 20 60 (consultoria tècnica) consultoriatecnica@cateb.cat
                        </p>
                    </div>
                </section>

            </header>
        </div>
    )
}
