import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './HistorialResultats.scss'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader';

import { Footer } from '../../components/Footer/Footer'
import { Solicitud } from './Solicitud/Solicitud'
import { ModalEliminarMuestra } from './Solicitud/ModalEliminarMuestra'
import { PaginationT } from '../../components/PaginationT/PaginationT'
import { useForm } from '../../hooks/useForm'
import { getURLQuery, getValuesParams } from '../../helpers/helpers'

export const HistorialResultats = () => {

    const [fetchSolicitudes, fetchSolicitudesData] = useFetch('GET', `/clientes/solicitudes`)

    const [fetchParametros, fetchParametrosData] = useFetch('GET', `/parametros`)

    useEffect(() => {

        fetchSolicitudes()
        fetchParametros()

    }, [])


    // Modal eliminar muestra 

    const [showModalEliminarMuestra, setShowModalEliminarMuestra] = useState({
        value: false,
        id: -1
    });

    const handleCloseModalEliminarMuestra = () => setShowModalEliminarMuestra({
        ...showModalEliminarMuestra,
        value: false,
    });

    const handleShowModalEliminarMuestra = (id) => {
        setShowModalEliminarMuestra({
            value: true,
            id
        })
    }

    // Search

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
    });

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        fetchSolicitudes({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page])


    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto mt-4 mb-5 mx-4">
                <h5 className='mt-2 p-3 text-center'>Historial de resultats</h5>
                {
                    fetchSolicitudesData.loading || fetchParametrosData.loading ? <Loader /> : <>

                        <Table responsive className='tableMain'>
                            <thead>
                                <tr>
                                    <th>ID sol·licitud</th>
                                    {/* <th>Tipus producte</th> */}
                                    <th>Total de mostres</th>
                                    <th>Data de creació</th>
                                    <th>Estat</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fetchParametrosData.data?.data?.estados_solicitud && fetchSolicitudesData.data?.data?.data?.map((item, index) => (
                                        <Solicitud
                                            key={index}
                                            item={item}
                                            index={index}
                                            fetchParametrosData={fetchParametrosData}
                                            handleShowModalEliminarMuestra={handleShowModalEliminarMuestra}
                                        />
                                    ))

                                }

                                {
                                    fetchSolicitudesData.data?.data?.data?.length === 0 && <>
                                        <tr className='text-center'>
                                            <td className='py-5 ' colSpan={6}>
                                                <h5 className='fw-normal'>No hi ha sol·licituds per mostrar</h5>
                                            </td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </Table>
                        <PaginationT
                            total={fetchSolicitudesData?.data?.data?.total}
                            handleFormChange={handleFormChange}
                            formValuesSearch={formValuesSearch}
                            perPage={fetchSolicitudesData?.data?.data?.per_page}
                        />
                    </>
                }
            </div>
            <div className="mt-auto">
                <Footer />
            </div>
            <ModalEliminarMuestra
                showModalEliminarMuestra={showModalEliminarMuestra}
                handleCloseModalEliminarMuestra={handleCloseModalEliminarMuestra}
                fetchSolicitudes={fetchSolicitudes}
            />
        </>

    )
}
