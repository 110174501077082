import React, { useEffect, useState } from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { InputText } from '../../../../components/Inputs/InputText'
import { InputSelect } from '../../../../components/Inputs/InputSelect'
import { useFetch } from '../../../../hooks/useFetch'
import { useForm } from '../../../../hooks/useForm'
import { useValidator } from '../../../../hooks/useValidator'
import { Loader } from '../../../../components/Loader/Loader'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputTextNormal } from '../../../../components/Inputs/InputTextNormal'
import { isCheckTrue } from '../../../../helpers/helpers'
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import { ListAutocomplete } from '../../../../components/ListAutocomplete/ListAutocomplete'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import _ from 'lodash'


export const ModalEditarMuestra = ({
    showModalEditarMuestra,
    handleCloseModalEditarMuestra,
    handleShowModalCopiarDatos,
    fetchParametrosData,
    muestras,
    solicitud_id,
    fetchSolicitudesMuestras,
    setMuestras,
}) => {

    const [fetchEditarMuestra, fetchEditarMuestraData] = useFetch('GET', `/clientes/muestras`)

    const [formEditarMuestra, handleInputChangeEditarMuestra, handleFormChangeEditarMuestra, resetFormEditMuestra] = useForm({
        // Datos de la muestra 
        id: '',
        via: '',
        via_numero: '',
        piso: '',
        puerta: '',
        codigo_postal_id: '',
        localitat: '',
        provincia: '',
        poblacion: '',
        vigueta_localizacion: '', // --> opcion OTROS activa altres
        vigueta_localizacion_altres: '',
        // Datos del edificio
        tipo_edificio_id: '',
        otro_tipo_edificio: '',// de este no hay campo
        num_plantas: '',
        num_viviendas: '', // creo que es numero de habitantes
        fecha_construccion: '',
        // Descripcion de la vigueta
        vigueta_fisurada: false, // --> opcion SI desbloquea descripcion
        vigueta_descripcion_fisura: '', // --> opcion OTROS desbloquea altres
        vigueta_descripcion_fisura_altres: '',
        vigueta_polvo_blanquinoso: false,
        vigueta_color_superficial: '',
        vigueta_color_interior: '',
        vigueta_corrosion_visible: false,
        vigueta_oxido_adherido: false,
        vigueta_otros_aspectos: '',
        anotaciones: '',
        // Copiar a muestras vacias
        copiar_a_vacia: false,
        pais: '',
        // Propietario
        propietario_nombre: '',
        propietario_apellidos: '',
        propietario_documento: '',
        propietario_email: '',

        lat: '',
        lng: '',
    })

    const [valuesValidEditarMuestra, handleVerifiedValuesEditarMuestra, handleResetValuesEditarMuestra] = useValidator({
        // Datos de la muestra 
        id: {
            required: {
                isOk: true,
                msgError: [`L'ID és obligatori.`]
            }
        },
        via: {
            required: {
                isOk: true,
                msgError: [`La via és obligatòria.`]
            }
        },
        via_numero: {
            required: {
                isOk: true,
                msgError: [`El número de via és obligatòria.`]
            }
        },
        // piso: {
        //     required: {
        //         isOk: true,
        //         msgError: [`El pis és obligatori.`]
        //     }
        // },
        // puerta: {
        //     required: {
        //         isOk: true,
        //         msgError: [`La porta és obligatòria.`]
        //     }
        // },
        codigo_postal: {
            required: {
                isOk: true,
                msgError: [`El codi postal és obligatori.`]
            }
        },
        localitat: {
            required: {
                isOk: true,
                msgError: [`La localitat és obligatòria.`]
            }
        },
        provincia: {
            required: {
                isOk: true,
                msgError: [`La província és obligatòria.`]
            }
        },
        vigueta_localizacion: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
        // vigueta_localizacion_altres: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        // Datos del edificio
        tipo_edificio_id: {
            required: {
                isOk: true,
                msgError: [`El tipus d'edifici és obligatori.`]
            }
        },
        otro_tipo_edificio: {
            required: {
                isOk: true,
                msgError: [`L'altre tipus d'edifici és obligatori.`]
            }
        },
        // num_plantas: {
        //     required: {
        //         isOk: true,
        //         msgError: [`El nombre de plantes és obligatori.`]
        //     }
        // },
        // num_viviendas: {
        //     required: {
        //         isOk: true,
        //         msgError: [`El nombre d'habitatges és obligatori.`]
        //     }
        // },
        // fecha_construccion: {
        //     required: {
        //         isOk: true,
        //         msgError: [`La data de construcció és obligatòria.`]
        //     }
        // },
        // Descripcion de la vigueta
        vigueta_fisurada: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
        // vigueta_descripcion_fisura: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        // vigueta_descripcion_fisura_altres: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        vigueta_polvo_blanquinoso: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
        // vigueta_color_superficial: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        // vigueta_color_interior: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        vigueta_corrosion_visible: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
        vigueta_oxido_adherido: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
        // vigueta_otros_aspectos: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },
        // anotaciones: {
        //     required: {
        //         isOk: true,
        //         msgError: [`Aquest camp és obligatori.`]
        //     }
        // },

        pais: {
            required: {
                isOk: true,
                msgError: [`La localitat és obligatòria.`]
            }
        },

        // Propietario

        propietario_nombre: {
            required: {
                isOk: true,
                msgError: [`El nom del propietari és obligatori.`]
            }
        },
        // propietario_apellidos: {
        //     required: {
        //         isOk: true,
        //         msgError: [`El nom del propietari és obligatori.`]
        //     }
        // },
        propietario_documento: {
            required: {
                isOk: true,
                msgError: [`El document del propietari és obligatori.`]
            }
        },
        // propietario_email: {
        //     required: {
        //         isOk: true,
        //         msgError: [`L'email del propietari és obligatori.`]
        //     }
        // }
    })

    const [valuesValidEditarMuestraViguetaLocaAltres, handleVerifiedValuesEditarMuestraViguetaLocaAltres] = useValidator({

        vigueta_localizacion_altres: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
    })

    const [valuesValidEditarMuestraViguetaDescFisura, handleVerifiedValuesEditarMuestraViguetaDescFisura] = useValidator({

        vigueta_descripcion_fisura: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
    })

    const [valuesValidEditarMuestraViguetaDescFisuraAltres, handleVerifiedValuesEditarMuestraViguetaDescFisuraAltres] = useValidator({

        vigueta_descripcion_fisura_altres: {
            required: {
                isOk: true,
                msgError: [`Aquest camp és obligatori.`]
            }
        },
    })

    useEffect(() => {

        if (fetchEditarMuestraData.data?.data) {

            const muestra = fetchEditarMuestraData.data?.data

            // console.log('muestra get', muestra)

            handleFormChangeEditarMuestra({
                // Datos de la muestra 
                id: muestra.id,
                via: muestra.via,
                via_numero: muestra.via_numero,
                piso: muestra.piso,
                puerta: muestra.puerta,
                codigo_postal: muestra.codigo_postal,
                localitat: muestra.localitat,
                vigueta_localizacion: muestra.vigueta_localizacion,
                vigueta_localizacion_altres: muestra.vigueta_localizacion_altres || '',
                // Datos del edificio
                tipo_edificio_id: muestra.tipo_edificio_id,
                otro_tipo_edificio: 'no existe',
                num_plantas: muestra.num_plantas,
                num_viviendas: muestra.num_viviendas,
                fecha_construccion: muestra.fecha_construccion,
                // Descripcion de la vigueta
                vigueta_fisurada: muestra.vigueta_fisurada === null ? '' : isCheckTrue(muestra.vigueta_fisurada),
                vigueta_descripcion_fisura: muestra.vigueta_descripcion_fisura || '',
                // vigueta_descripcion_fisura_altres: muestra.vigueta_localizacion_altres === 'buit' ? '' : muestra.vigueta_localizacion_altres,
                vigueta_descripcion_fisura_altres: muestra.vigueta_descripcion_fisura_altres || '',
                vigueta_polvo_blanquinoso: muestra.vigueta_polvo_blanquinoso === null ? '' : isCheckTrue(muestra.vigueta_polvo_blanquinoso),
                vigueta_color_superficial: muestra.vigueta_color_superficial,
                vigueta_color_interior: muestra.vigueta_color_interior || '#FFFFFF',
                vigueta_corrosion_visible: muestra.vigueta_corrosion_visible === null ? '' : isCheckTrue(muestra.vigueta_corrosion_visible),
                vigueta_oxido_adherido: muestra.vigueta_oxido_adherido === null ? '' : isCheckTrue(muestra.vigueta_oxido_adherido),
                vigueta_otros_aspectos: muestra.vigueta_otros_aspectos,
                anotaciones: muestra.anotaciones,

                copiar_a_vacia: false,
                pais: muestra.pais,
                provincia: muestra.provincia,

                // Propietario
                propietario_nombre: muestra.propietario_nombre,
                propietario_apellidos: muestra.propietario_apellidos,
                propietario_documento: muestra.propietario_documento,
                propietario_email: muestra.propietario_email,
                propietario_persona_juridica: muestra.propietario_persona_juridica || 'persona',
                // propietario_persona_juridica: muestra.propietario_persona_juridica === null ? '' : isCheckTrue(muestra.propietario_persona_juridica),
                
                lat: muestra.lat || '',
                lng: muestra.lng || '',
            })
        }

    }, [fetchEditarMuestraData])

    const [fetchGuardarEditarMuestra, fetchGuardarEditarMuestraData] = useFetch('PUT', `/clientes/muestras`)

    const handleGuardarEditarMuestra = (e) => {

        e.preventDefault()

        // console.log(formEditarMuestra)

        let isCorrectVerified = [false, false, false, false]

        if (handleVerifiedValuesEditarMuestra(formEditarMuestra)) {
            isCorrectVerified[0] = true
        } else {
            isCorrectVerified[0] = false
        }

        if (formEditarMuestra.vigueta_localizacion === 'otros') {

            if (handleVerifiedValuesEditarMuestraViguetaLocaAltres(formEditarMuestra)) {
                isCorrectVerified[1] = true
            } else {
                isCorrectVerified[1] = false
            }
        } else {
            isCorrectVerified[1] = true
        }

        if (isCheckTrue(formEditarMuestra.vigueta_fisurada)) {

            if (handleVerifiedValuesEditarMuestraViguetaDescFisura(formEditarMuestra)) {
                isCorrectVerified[2] = true
            } else {
                isCorrectVerified[2] = false
            }

            if (formEditarMuestra.vigueta_descripcion_fisura === 'otros') {
                if (handleVerifiedValuesEditarMuestraViguetaDescFisuraAltres(formEditarMuestra)) {
                    isCorrectVerified[3] = true
                } else {
                    isCorrectVerified[3] = false
                }
            } else {
                isCorrectVerified[3] = true
            }

        } else {
            isCorrectVerified[2] = true
            isCorrectVerified[3] = true
        }

        // console.log(isCorrectVerified, formEditarMuestra)

        if ((isCorrectVerified.indexOf(false) === -1)) {

            // if (!formEditarMuestra.vigueta_descripcion_fisura_altres || formEditarMuestra.vigueta_descripcion_fisura_altres === 'buit') {

            // }

            console.table(
                [['vigueta_fisurada', formEditarMuestra.vigueta_fisurada, isCheckTrue(formEditarMuestra.vigueta_fisurada)],
                ['vigueta_polvo_blanquinoso', formEditarMuestra.vigueta_polvo_blanquinoso, isCheckTrue(formEditarMuestra.vigueta_polvo_blanquinoso)],
                ['vigueta_corrosion_visible', formEditarMuestra.vigueta_corrosion_visible, isCheckTrue(formEditarMuestra.vigueta_corrosion_visible)],
                ['vigueta_oxido_adherido', formEditarMuestra.vigueta_oxido_adherido, isCheckTrue(formEditarMuestra.vigueta_oxido_adherido)]],
            )

            const bodyTemp = {
                ...formEditarMuestra,
                vigueta_fisurada: isCheckTrue(formEditarMuestra.vigueta_fisurada),
                vigueta_polvo_blanquinoso: isCheckTrue(formEditarMuestra.vigueta_polvo_blanquinoso),
                vigueta_corrosion_visible: isCheckTrue(formEditarMuestra.vigueta_corrosion_visible),
                vigueta_oxido_adherido: isCheckTrue(formEditarMuestra.vigueta_oxido_adherido),
                // propietario_persona_juridica: isCheckTrue(formEditarMuestra.propietario_persona_juridica),
                vigueta_descripcion_fisura_altres: formEditarMuestra.vigueta_descripcion_fisura_altres,
            }

            if (bodyTemp.propietario_persona_juridica === 'empresa') delete bodyTemp.propietario_apellidos

            fetchGuardarEditarMuestra({
                id: formEditarMuestra.id,
                body: bodyTemp
            })
        }
    }

    useEffect(() => {

        if (fetchGuardarEditarMuestraData.data?.status === 'Success') {

            let muestrasTemp = [...muestras]

            muestrasTemp = muestrasTemp.filter(item => item.id !== showModalEditarMuestra.id)

            muestrasTemp = muestrasTemp.filter(item => item.localizacion_vigueta === null)

            if (muestrasTemp.length > 0) {

                handleShowModalCopiarDatos(formEditarMuestra)

            } else {
                if (solicitud_id) {
                    fetchSolicitudesMuestras({
                        id: solicitud_id
                    })
                }
                setMuestras([])
            }

            handleCloseModalEditarMuestra()
        }

    }, [fetchGuardarEditarMuestraData.data?.status])

    useEffect(() => {

        if (showModalEditarMuestra.value) {
            handleResetValuesEditarMuestra()
            setShowMasInfoFisurada(false)
            setShowMasInfoFisurada2(false)
            resetFormEditMuestra()
            fetchEditarMuestra({
                id: showModalEditarMuestra.id
            })
        }

    }, [showModalEditarMuestra.value])

    // useEffect(() => {

    //     console.log(formEditarMuestra)

    // }, [formEditarMuestra])

    const [showMasInfoFisurada, setShowMasInfoFisurada] = useState(false);
    const [showMasInfoFisurada2, setShowMasInfoFisurada2] = useState(false);

    useEffect(() => {

        if (formEditarMuestra.vigueta_fisurada) {
            if (formEditarMuestra.vigueta_fisurada === true || formEditarMuestra.vigueta_fisurada === 'true') {

                // console.log('DESCRIPCION DE LA FISURA')
                setShowMasInfoFisurada(true)
            } else {
                setShowMasInfoFisurada(false)
                handleFormChangeEditarMuestra({
                    ...formEditarMuestra,
                    vigueta_descripcion_fisura: ''
                })
            }
        }

    }, [formEditarMuestra.vigueta_fisurada])

    useEffect(() => {

        if (formEditarMuestra.vigueta_descripcion_fisura === 'otros') {

            // console.log('OTRA DESCRIPCION DE LA FISURA')
            setShowMasInfoFisurada2(true)
        } else {
            setShowMasInfoFisurada2(false)
        }

    }, [formEditarMuestra.vigueta_descripcion_fisura])

    const [showMasInfoLocalizacion, setShowMasInfoLocalizacion] = useState(false);

    useEffect(() => {

        if (formEditarMuestra.vigueta_localizacion === 'otros') {

            // console.log('OTRA DESCRIPCION DE LA FISURA')
            setShowMasInfoLocalizacion(true)
        } else {
            setShowMasInfoLocalizacion(false)
        }

    }, [formEditarMuestra.vigueta_localizacion])


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)
    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formEditarMuestra.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    codigo_postal: formEditarMuestra.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangeEditarMuestra({
                ...formEditarMuestra,
                provincia: '',
                localitat: '',
                pais: '',
            })
        }

    }, [formEditarMuestra.codigo_postal])

    useEffect(() => {

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data.status === 'Success') {

                const poblaciones = fetchPoblacionesData.data.data

                const poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeEditarMuestra({
                    ...formEditarMuestra,
                    provincia: '',
                    localitat: '',
                    pais: '',
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        // Find by poblacion and set

        if (formEditarMuestra.localitat && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data]

            const resp = poblacionesTemp.find(item => item.poblacion === formEditarMuestra.localitat)

            handleFormChangeEditarMuestra({
                ...formEditarMuestra,
                provincia: resp?.provincia,
                pais: resp?.pais,
            })
        }

    }, [formEditarMuestra.localitat, fetchPoblacionesData.data])


    // -------------------- Autocompletado de Google

    const getPlaceDetails = (placeId) => {

        return new Promise(function (resolve, reject) {

            placesService.getDetails({
                placeId,
            },
                (place) => {
                    resolve(place);
                }
            );
        });
    };

    const handleSelectOption = async (place) => {

        const placeDetails = await getPlaceDetails(place.place_id)

        // console.log('placeDetails', placeDetails);

        const location = _.groupBy(placeDetails?.address_components, 'types')

        handleFormChangeEditarMuestra({
            ...formEditarMuestra,
            codigo_postal: location?.['postal_code']?.[0]?.long_name || '',
            via_numero: location?.['street_number']?.[0]?.long_name || '',
            via: location?.['route']?.[0]?.long_name || '',
            lat: String(placeDetails?.geometry?.location?.lat() || ''),
            lng: String(placeDetails?.geometry?.location?.lng() || ''),
        })
        handleResetPredictions()

    }

    const handleResetPredictions = () => {

        getPlacePredictions({ input: '' });
    }

    // console.log('process.env.REACT_APP_GOOGLE_GEO_API_KEY', process.env.REACT_APP_GOOGLE_GEO_API_KEY);

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_GEO_API_KEY,
        options: {
            types: ['address'],
        }
    });

    const [isHoverAutocomplete, setisHoverAutocomplete] = useState(false);

    // -------------------- End Autocompletado de Google

    return (
        <>
            <Modal show={showModalEditarMuestra.value} onHide={handleCloseModalEditarMuestra} size='lg'>
                <Modal.Header className='border-0'>
                    <Modal.Title className='h4 mx-auto'>Mostra</Modal.Title>
                    <button type="button" className='btn-close mx-0' aria-label='Close' onClick={handleCloseModalEditarMuestra}></button>
                </Modal.Header>
                {
                    fetchEditarMuestraData.loading ? <Loader /> : <>
                        <Modal.Body>
                            <div className="container">

                                <Form className='row'>

                                    <div className="col-12 mb-2">
                                        <h5>Dades del propietari de l'habitatge/edifici</h5>
                                    </div>
                                    <div className="mb-3 col-12">
                                        <p className='mb-1'>Tipo</p>
                                        <InputRadios
                                            name='propietario_persona_juridica'
                                            onChange={handleInputChangeEditarMuestra}
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                            values={[{ id: 'empresa', nombre: 'Empresa' }, { id: 'persona', nombre: 'Persona' }]}
                                            value={fetchEditarMuestraData.data?.data?.propietario_persona_juridica || 'persona'}
                                        />
                                    </div>

                                    <Form.Group className="mb-4 col-sm-6 col-lg-3">
                                        <InputText
                                            label='CIF/NIF *'
                                            className=''
                                            name='propietario_documento'
                                            value={formEditarMuestra.propietario_documento}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="propietario_documento"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-sm-6 col-lg-4">
                                        <InputText
                                            label='Nom *'
                                            className=''
                                            name='propietario_nombre'
                                            value={formEditarMuestra.propietario_nombre}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="propietario_nombre"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    {
                                        formEditarMuestra.propietario_persona_juridica === 'persona' &&
                                        <Form.Group className="mb-4 col-sm-12 col-lg-5">
                                            <InputText
                                                label='Cognoms *'
                                                className=''
                                                name='propietario_apellidos'
                                                value={formEditarMuestra.propietario_apellidos}
                                                onChange={handleInputChangeEditarMuestra}
                                                placeholder="propietario_apellidos"
                                                validation={valuesValidEditarMuestra}
                                                fetchData={fetchEditarMuestraData}
                                            />
                                        </Form.Group>
                                    }

                                    {/* <Form.Group className="mb-4 col-sm-12 col-lg-6">
                                        <InputText
                                            label='Adreça electrònica'
                                            className=''
                                            name='propietario_email'
                                            value={formEditarMuestra.propietario_email}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="propietario_email"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group> */}

                                    <div className="col-12 mb-2">
                                        <h5>Dades de la mostra</h5>
                                    </div>

                                    {/* <Form.Group className="mb-4 col-lg-5">
                                        <InputText
                                            label='Adreça *'
                                            className=''
                                            name='via'
                                            value={formEditarMuestra.via}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="via"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group> */}

                                    <Form.Group className="mb-4 col-lg-5 position-relative">
                                        <div className="form-floating">
                                            <InputText
                                                label='Adreça*'
                                                className=''
                                                name='via'
                                                value={formEditarMuestra.via}
                                                onChange={(evt) => {
                                                    getPlacePredictions({ input: evt.target.value });
                                                    handleInputChangeEditarMuestra(evt);
                                                }}
                                                placeholder="via"
                                                validation={valuesValidEditarMuestra}
                                                fetchData={fetchEditarMuestraData}
                                                onBlur={(e) => { isHoverAutocomplete ? e.preventDefault() : handleResetPredictions() }}
                                            />
                                            <ListAutocomplete
                                                list={placePredictions}
                                                handleClick={handleSelectOption}
                                                nameTitle='description'
                                                onMouseOver={() => setisHoverAutocomplete(true)}
                                                onMouseOut={() => setisHoverAutocomplete(false)}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label='Número *'
                                            className=''
                                            name='via_numero'
                                            value={formEditarMuestra.via_numero}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="via_numero"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label='Pis'
                                            className=''
                                            name='piso'
                                            value={formEditarMuestra.piso}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="piso"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label='Porta'
                                            className=''
                                            name='puerta'
                                            value={formEditarMuestra.puerta}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="puerta"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <div className="col-12"></div>

                                    {/* <Form.Group className="mb-4 col-sm-6 col-lg-3">
                                        <InputSelect
                                            label='País*'
                                            className=''
                                            name='pais'
                                            values={fetchParametrosData.data?.data?.paises}
                                            value={formEditarMuestra.pais}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="pais"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group> */}

                                    <Form.Group className="mb-4 col-sm-6 col-lg-3">
                                        <InputText
                                            label='Codi Postal*'
                                            className=''
                                            name='codigo_postal'
                                            value={formEditarMuestra.codigo_postal}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="codigo_postal"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-sm-6 col-lg-5">
                                        <InputSelect
                                            label='Localitat *'
                                            className=''
                                            name='localitat'
                                            values={poblaciones}
                                            value={formEditarMuestra.localitat}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="localitat"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                            disabled={!fetchPoblacionesData.data || formEditarMuestra.codigo_postal?.length !== 5}
                                        />
                                    </Form.Group>

                                    <div className="col-12"></div>

                                    <Form.Group className="mb-4 col-lg-4">
                                        <InputSelect
                                            label='Localització de la bigueta *'
                                            className=''
                                            name='vigueta_localizacion'
                                            values={fetchParametrosData.data?.data?.localizaciones_vigueta}
                                            value={formEditarMuestra.vigueta_localizacion}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="vigueta_localizacion"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>
                                    {
                                        showMasInfoLocalizacion && <>
                                            <Form.Group className="mb-4 col-md-7">
                                                <InputText
                                                    label='Altres dades *'
                                                    className=''
                                                    name='vigueta_localizacion_altres'
                                                    value={formEditarMuestra.vigueta_localizacion_altres}
                                                    onChange={handleInputChangeEditarMuestra}
                                                    placeholder="vigueta_localizacion_altres"
                                                    validation={valuesValidEditarMuestraViguetaLocaAltres}
                                                    fetchData={fetchEditarMuestraData}
                                                />
                                            </Form.Group>
                                        </>
                                    }

                                    <div className="col-12 mb-2">
                                        <h5>Dades de l'edifici</h5>
                                    </div>

                                    <Form.Group className="mb-4 col-md-6 col-lg-4">
                                        <InputSelect
                                            label='Tipus edifici *'
                                            className=''
                                            name='tipo_edificio_id'
                                            values={fetchParametrosData.data?.data?.tipos_edificio}
                                            value={formEditarMuestra.tipo_edificio_id}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="tipo_edificio_id"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4 col-md-6 col-lg-4">
                                        <InputText
                                            label='Nombre de plantes'
                                            className=''
                                            type='number'
                                            name='num_plantas'
                                            value={formEditarMuestra.num_plantas}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="num_plantas"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                            min={0}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-4">
                                        <InputText
                                            label='Nombre total habitatges'
                                            className=''
                                            type='number'
                                            name='num_viviendas'
                                            value={formEditarMuestra.num_viviendas}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="num_viviendas"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                            min={0}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-4">
                                        <InputText
                                            label='Any construcció'
                                            className=''
                                            type='number'
                                            name='fecha_construccion'
                                            value={formEditarMuestra.fecha_construccion}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="fecha_construccion"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                            min={0}
                                        />
                                    </Form.Group>

                                    <div className="col-12 mb-2">
                                        <h5>Descripció de la bigueta</h5>
                                    </div>

                                    <div className="mb-3 col-12">
                                        <p className='mb-1'>Fissurada</p>
                                        {
                                            fetchEditarMuestraData.data && <>
                                                <InputRadios
                                                    name='vigueta_fisurada'
                                                    onChange={handleInputChangeEditarMuestra}
                                                    validation={valuesValidEditarMuestra}
                                                    fetchData={fetchEditarMuestraData}
                                                    values={[{ id: true, nombre: 'Si' }, { id: false, nombre: 'No' }]}
                                                    value={fetchEditarMuestraData.data?.data?.vigueta_fisurada}
                                                />
                                            </>
                                        }
                                    </div>
                                    {
                                        showMasInfoFisurada && <>
                                            <Form.Group className="mb-4 col-md-6">
                                                <InputSelect
                                                    label='Descripció de la fissura *'
                                                    className=''
                                                    name='vigueta_descripcion_fisura'
                                                    values={fetchParametrosData.data?.data?.descripciones_fisura}
                                                    value={formEditarMuestra.vigueta_descripcion_fisura}
                                                    onChange={handleInputChangeEditarMuestra}
                                                    placeholder="vigueta_descripcion_fisura"
                                                    validation={valuesValidEditarMuestraViguetaDescFisura}
                                                    fetchData={fetchEditarMuestraData}
                                                />
                                            </Form.Group>
                                        </>
                                    }
                                    {
                                        showMasInfoFisurada && showMasInfoFisurada2 && <>
                                            <Form.Group className="mb-4 col-md-12">
                                                <InputText
                                                    label='Altres dades *'
                                                    className=''
                                                    name='vigueta_descripcion_fisura_altres'
                                                    value={formEditarMuestra.vigueta_descripcion_fisura_altres}
                                                    onChange={handleInputChangeEditarMuestra}
                                                    placeholder="vigueta_descripcion_fisura_altres"
                                                    validation={valuesValidEditarMuestraViguetaDescFisuraAltres}
                                                    fetchData={fetchEditarMuestraData}
                                                />
                                            </Form.Group>
                                        </>
                                    }

                                    <div className="mb-3 col-12">
                                        <p className='mb-1'>S’observa pols blanquinosa</p>
                                        {
                                            fetchEditarMuestraData.data && <>
                                                <InputRadios
                                                    name='vigueta_polvo_blanquinoso'
                                                    onChange={handleInputChangeEditarMuestra}
                                                    validation={valuesValidEditarMuestra}
                                                    fetchData={fetchEditarMuestraData}
                                                    values={[{ id: true, nombre: 'Si' }, { id: false, nombre: 'No' }]}
                                                    value={fetchEditarMuestraData.data?.data?.vigueta_polvo_blanquinoso}
                                                />
                                            </>
                                        }
                                    </div>

                                    <Form.Group className="mb-4 col-md-6 col-lg-3">
                                        <InputText
                                            label='Color superficial'
                                            className=''
                                            name='vigueta_color_superficial'
                                            value={formEditarMuestra.vigueta_color_superficial}
                                            onChange={handleInputChangeEditarMuestra}
                                            placeholder="vigueta_color_superficial"
                                            validation={valuesValidEditarMuestra}
                                            fetchData={fetchEditarMuestraData}
                                        />
                                    </Form.Group>

                                    <div className="mb-4 col-md-6 col-lg-3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Color interior *"
                                        >
                                            <Form.Control
                                                type="text"
                                                name='email'
                                                placeholder="email"
                                                value={formEditarMuestra.vigueta_color_interior}
                                                disabled
                                            />
                                            <input
                                                placeholder="email"
                                                type="color"
                                                className='input-color-paleta'
                                                id="floatingInput"
                                                name='vigueta_color_interior'
                                                value={formEditarMuestra.vigueta_color_interior}
                                                onChange={handleInputChangeEditarMuestra}
                                            />

                                        </FloatingLabel>
                                    </div>

                                    <div className="mb-3 col-12">
                                        <p className='mb-1'>Es veu corrosió a l’armadura</p>
                                        {
                                            fetchEditarMuestraData.data && <>
                                                <InputRadios
                                                    name='vigueta_corrosion_visible'
                                                    onChange={handleInputChangeEditarMuestra}
                                                    validation={valuesValidEditarMuestra}
                                                    fetchData={fetchEditarMuestraData}
                                                    values={[{ id: true, nombre: 'Si' }, { id: false, nombre: 'No' }]}
                                                    value={fetchEditarMuestraData.data?.data?.vigueta_corrosion_visible}
                                                />
                                            </>
                                        }

                                    </div>

                                    <div className="mb-3 col-12">
                                        <p className='mb-1'>Queda òxid adherit a la part trencada de la bigueta</p>
                                        {
                                            fetchEditarMuestraData.data && <>
                                                <InputRadios
                                                    name='vigueta_oxido_adherido'
                                                    onChange={handleInputChangeEditarMuestra}
                                                    validation={valuesValidEditarMuestra}
                                                    fetchData={fetchEditarMuestraData}
                                                    values={[{ id: true, nombre: 'Si' }, { id: false, nombre: 'No' }]}
                                                    value={fetchEditarMuestraData.data?.data?.vigueta_oxido_adherido}
                                                />
                                            </>
                                        }

                                    </div>

                                    <Form.Group className="mb-4 col-12">
                                        <InputText
                                            as="textarea"
                                            label="Altres aspectes"
                                            placeholder="Leave a comment here"
                                            className=''
                                            style={{ height: '100px' }}
                                            onChange={handleInputChangeEditarMuestra}
                                            name='vigueta_otros_aspectos'
                                            value={formEditarMuestra.vigueta_otros_aspectos}
                                            validation={valuesValidEditarMuestra}
                                        />
                                    </Form.Group>

                                    <div className="col-12 mb-2">
                                        <h5>Anotacions</h5>
                                    </div>

                                    <Form.Group className="mb-3">
                                        <InputTextNormal
                                            as="textarea"
                                            rows={3}
                                            // label="Anotacions"
                                            placeholder=""
                                            className=''
                                            // style={{ height: '100px' }}
                                            validation={valuesValidEditarMuestra}
                                            onChange={handleInputChangeEditarMuestra}
                                            name='anotaciones'
                                            value={formEditarMuestra.anotaciones}
                                        />
                                    </Form.Group>

                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='border-0'>
                            <Button variant="default" onClick={handleCloseModalEditarMuestra}>
                                Sortir
                            </Button>
                            <ButtonSpinner
                                variant="primary" type="submit" className="ms-2"
                                handleSubmit={handleGuardarEditarMuestra} fetchDataLoading={[fetchGuardarEditarMuestraData.loading]}
                                value='Desar mostra' icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                        </Modal.Footer>
                    </>
                }

            </Modal>
        </>
    )
}
