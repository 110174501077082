import React, { useReducer, useEffect, useState } from 'react'

import { AppRouter } from './routers/AppRouter'
import { AuthContext } from './auth/AuthContext'
import { authReducer } from './auth/authReducer'

import 'bootstrap/dist/css/bootstrap.min.css';
import './Aluminosis.css';
import jwt from 'jwt-decode';
import  { Redirect } from 'react-router-dom'

import { types } from './types/types';
import ModalProteccionDatos from './components/Modal/ModalProteccionDatos';
import { useFetch } from './hooks/useFetch';
import { ProteccionDatos } from './components/ProteccionDatos/ProteccionDatos';

const init = () => {
    
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE)) || { logged: false, lang: process.env.REACT_APP_LANGUAGE_DEFAULT};
}

export const Aluminosis = () => {

    
    const [ user, dispatch ] = useReducer(authReducer, {}, init);
    
    useEffect(() => {

        localStorage.setItem( process.env.REACT_APP_MAIN_VARIABLE, JSON.stringify(user) );
        
        if (user.token) {
            const exp = jwt(user.token).exp
            const hoy = Date.now()

            // console.log(new Date(hoy) , "--",  new Date(exp * 1000))
            
            if ( hoy >= exp * 1000) {
                // console.log('token expirado')
                dispatch({
                    type: types.logout,
                    lang: user.lang
                });            
                return <Redirect to='/login' />
            }
        }

    }, [user])

    // console.log(process.env.REACT_APP_ENVIRONMENT)
   
    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AppRouter />
            { user.token &&  <ProteccionDatos /> }
        </AuthContext.Provider>
    )
}
