import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { InputSelect } from '../../../../components/Inputs/InputSelect'
import { InputText } from '../../../../components/Inputs/InputText'
import { useFetch } from '../../../../hooks/useFetch'
import { useForm } from '../../../../hooks/useForm'
import { useValidator } from '../../../../hooks/useValidator'

export const ModalAgregarMuestras = ({ showModalAgregarMuestras, handleCloseModalAgregarMuestras, fetchSolicitudesMuestras,
    tipoTest, solicitud_id, setMuestras, setSolicitud_id, muestras }) => {

    const [fetchAgregarMuestras, fetchAgregarMuestrasData] = useFetch('POST', `/clientes/muestras/anadir`)

    useEffect(() => {

        if (fetchAgregarMuestrasData.data?.status === 'Success') {

            if (solicitud_id) {

                // console.log('fetchSolicitudesMuestras en modal agregar')
                fetchSolicitudesMuestras({
                    id: solicitud_id
                })

            } else {

                setSolicitud_id(fetchAgregarMuestrasData.data?.data?.muestras?.[0].solicitud_id)
            }

            setMuestras([])
            handleCloseModalAgregarMuestras()
        }

    }, [fetchAgregarMuestrasData.data])

    const handleSubmitAgregarMuestras = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesNumMuestras(formNumMuestras)) {

            const body = {}

            body.num_muestras = Number(formNumMuestras.num_muestras)

            if (solicitud_id) {
                body.solicitud_id = solicitud_id
                if (formNumMuestras.copiar_de_muestra)
                    body.copiar_de_muestra = Number(formNumMuestras.copiar_de_muestra)
            } else {
                body.tipo_producto_id = tipoTest
            }

            fetchAgregarMuestras({
                body
            })
        }
    }

    const [formNumMuestras, handleInputChangeNumMuestras, form, resetFormNumMuestras] = useForm({
        num_muestras: '',
        copiar_de_muestra: null
    })

    const [valuesValidNumMuestras, handleVerifiedValuesNumMuestras] = useValidator({
        num_muestras: {
            required: {
                isOk: true,
                msgError: [`El número de col·legiat és obligatori.`]
            }
        }
    })

    // Copiar a partir de una muestra

    const [muestrasLlenas, setMuestrasLlenas] = useState([])

    useEffect(() => {

        if (showModalAgregarMuestras) {

            const muestrasTemp = [...muestras]

            let res = muestrasTemp.filter(item => item.estado === 'Revisat')

            res = res.map(item => {
                return ({
                    id: item.id,
                    nombre: item.id
                })
            })

            setMuestrasLlenas(res)

        }
    }, [showModalAgregarMuestras])


    return (
        <>

            <Modal show={showModalAgregarMuestras} onHide={handleCloseModalAgregarMuestras} size='md'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 mb-4">
                                <h5 className='text-center'>Indica'ns el nombre de mostres que vols realitzar</h5>
                            </div>
                            <div className="col-lg-8">
                                <Form onSubmit={handleSubmitAgregarMuestras}>
                                    <Form.Group className="mb-4">
                                        <InputText
                                            label='Quantitat de mostres *'
                                            className=''
                                            type="number"
                                            name='num_muestras'
                                            value={formNumMuestras.num_muestras}
                                            onChange={handleInputChangeNumMuestras}
                                            placeholder="num_muestras"
                                            validation={valuesValidNumMuestras}
                                            fetchData={fetchAgregarMuestrasData}
                                            min={0}
                                        />
                                    </Form.Group>

                                    {
                                        muestrasLlenas.length > 0 && <Form.Group className="mb-4">
                                            <InputSelect
                                                label='Copiar dades des de la mostra'
                                                className=''
                                                name='copiar_de_muestra'
                                                values={muestrasLlenas}
                                                value={formNumMuestras.copiar_de_muestra}
                                                onChange={handleInputChangeNumMuestras}
                                                placeholder="copiar_de_muestra"
                                                validation={valuesValidNumMuestras}
                                                fetchData={fetchAgregarMuestrasData}
                                            />
                                        </Form.Group>
                                    }

                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalAgregarMuestras}>
                        Sortir
                    </Button>
                    {/* <Button variant="primary" onClick={handleSubmitAgregarMuestras} className='ms-2'>
                        Continuar
                    </Button> */}
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitAgregarMuestras} fetchDataLoading={[fetchAgregarMuestrasData.loading]}
                        value='Afegir mostres'
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
